<template>
<div class="py-5">
  <h3 v-if="shikenId == 1">読者様特典 ＣＢＴ方式試験 トライアル版</h3>
  <h3 v-else>読者様特典 ＣＢＴ試験プログラム体験模試</h3>
  <ol>
    <li>制限時間を意識して、パソコン上で問題を解く練習をしましょう。
      <br>
      本アプリは、パソコンにてご利用ください。<br>
【推奨環境】<br>
・Google Chrome（最新版）<br>・Microsoft Edge（最新版）<br>・Firefox（最新版）<br>・Safari （最新版）


    </li>
    <li>画面表示は、実際のCBT方式試験とは同一ではありません。また、改善のため予告なく変更される場合がありますので、あらかじめご了承ください。</li>
  </ol>

  <h3>注意事項</h3>
  <ol>
    <li>出題形式は、<strong>正誤式 30問、三答択一式30問</strong>です。
</li>
    <li>試験時間は、<strong>90分</strong>です。「試験開始」ボタンをクリックすると試験がスタートし、画面右上に残り時間が表示されます。
</li>
    <li>試験問題は、特に指示のない限り2023年４月１日現在施行の法令等に基づいて解答してください。
</li>
<li>「後で見直す」ボタンをクリックすると、表示されている問題に赤い目印がつき、後で見直すためのチェックを入れることができます。</li>
    <li>画面下の「解答状況」ボタンをクリックすると、「未解答」、「解答ずみ」、「チェックしたもの」、「解答中」の問題が一覧で確認できます。
</li>
<li>画面下の「<strong>電卓</strong>」ボタンをクリックすると、画面上に電卓機能が表示されますので、計算が必要な場合にご利用ください。</li>
<li>問１～問30は、文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。<br>
問31～問60は、文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選択してください。
</li>
    <li>60点満点で、<strong>36点以上</strong>が合格です。
</li>
    <li>試験終了後、試験結果が表示されます。<strong>※この機能は実際のＣＢＴ試験にはありません。</strong>
</li>
<li v-if="$route.name == 'home-text'">本ＣＢＴ試験プログラム体験模試は、過去問題から厳選して出題しています。出題年月は問題文最後のカッコ内の数字で記載しています。</li>
  </ol>
<hr class="mt-5"/>
<div class="text-end mt-4">
  <button v-if="$route.name == 'home'" @click="exec(1)" class="btn btn-primary">試験開始</button>
  <button v-else @click="exec(2)" class="btn btn-primary">試験開始</button>
</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from "vue-property-decorator";
import HttpClient from "../services/httpClient";
import BasicUtil from "../services/basicUtil";
import StorageService from "../services/storage";


@Component({
  components: {}
})
export default class HomeView extends Vue {
  private dao: HttpClient = new HttpClient();
  private StorageService: StorageService = new StorageService();

  private grade: string = this.$route.params.grade ?? "3";
  private shikenId: number = 1
  created() {
    if (this.$route.name == "home") {
      const isAuth = BasicUtil.isAuth("3");
    if (!isAuth) this.$router.push("/auth");
    } else {
      const isAuth = BasicUtil.isAuth("33");
    if (!isAuth) this.$router.push("/auth-text");
    }
    
  }
  mounted() {
    const shikenId = this.$route.name == "home" ? 1 : 2
    this.shikenId = shikenId
    new BasicUtil().removeBasicSetting(shikenId);
    new StorageService().removeCurrentData();
  }

  private exec(shikenId: number) {
    const datas = new HttpClient().getQuestionsFromAnswerType(shikenId, 1)
    this.$store.commit("setQuestionBacks", []);
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    this.$store.commit("setIsRe", false)
    this.$store.commit("setGoal", new Date().getTime() + 90 * 60 * 1000);
    new BasicUtil().setBasicSetting(shikenId);
    this.$router.push(`/exam/${shikenId}/1`);
  }

}
</script>
<style scoped>
h3 {
  font-size: 1.1em;
}
</style>
