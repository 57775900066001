<template>
    <div class="header">
    <button class="titleBtn btn btn-link"
      @click="tapLogo"
    >ファイナンシャル・プランニング技能検定３級  学科試験版</button>

    <hr class="m-0"/>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";

@Component
export default class Header extends Vue {

  created() {
  }
  mounted() {}

  tapLogo() {
    if (this.$store.state.data.length == 0) {
    if (this.$route.name == "home") return location.href = "/";
    return location.href = "/text";
    } else {
      const shikenId = this.$store.state.data[0].shikenId;
    if (shikenId == 1) return location.href = "/";
    return location.href = "/text";
    }
    
  }
}
</script>
<style scoped>
.header {
  height: 70px;
}

.header a {
  line-height: 70px;
  text-decoration: none;
  font-weight: bold;
  color: #212529;
}
.header .titleBtn {
  line-height: 70px;
  text-decoration: none;
  font-weight: bold;
  color: #212529;
}

</style>