var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"py-5"},[(_vm.shikenId == 1)?_c('h3',[_vm._v("読者様特典 ＣＢＴ方式試験 トライアル版")]):_c('h3',[_vm._v("読者様特典 ＣＢＴ試験プログラム体験模試")]),_vm._m(0),_c('h3',[_vm._v("注意事項")]),_c('ol',[_vm._m(1),_vm._m(2),_c('li',[_vm._v("試験問題は、特に指示のない限り2023年４月１日現在施行の法令等に基づいて解答してください。 ")]),_c('li',[_vm._v("「後で見直す」ボタンをクリックすると、表示されている問題に赤い目印がつき、後で見直すためのチェックを入れることができます。")]),_c('li',[_vm._v("画面下の「解答状況」ボタンをクリックすると、「未解答」、「解答ずみ」、「チェックしたもの」、「解答中」の問題が一覧で確認できます。 ")]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),(_vm.$route.name == 'home-text')?_c('li',[_vm._v("本ＣＢＴ試験プログラム体験模試は、過去問題から厳選して出題しています。出題年月は問題文最後のカッコ内の数字で記載しています。")]):_vm._e()]),_c('hr',{staticClass:"mt-5"}),_c('div',{staticClass:"text-end mt-4"},[(_vm.$route.name == 'home')?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.exec(1)}}},[_vm._v("試験開始")]):_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.exec(2)}}},[_vm._v("試験開始")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ol',[_c('li',[_vm._v("制限時間を意識して、パソコン上で問題を解く練習をしましょう。 "),_c('br'),_vm._v(" 本アプリは、パソコンにてご利用ください。"),_c('br'),_vm._v(" 【推奨環境】"),_c('br'),_vm._v(" ・Google Chrome（最新版）"),_c('br'),_vm._v("・Microsoft Edge（最新版）"),_c('br'),_vm._v("・Firefox（最新版）"),_c('br'),_vm._v("・Safari （最新版） ")]),_c('li',[_vm._v("画面表示は、実際のCBT方式試験とは同一ではありません。また、改善のため予告なく変更される場合がありますので、あらかじめご了承ください。")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("出題形式は、"),_c('strong',[_vm._v("正誤式 30問、三答択一式30問")]),_vm._v("です。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("試験時間は、"),_c('strong',[_vm._v("90分")]),_vm._v("です。「試験開始」ボタンをクリックすると試験がスタートし、画面右上に残り時間が表示されます。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("画面下の「"),_c('strong',[_vm._v("電卓")]),_vm._v("」ボタンをクリックすると、画面上に電卓機能が表示されますので、計算が必要な場合にご利用ください。")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("問１～問30は、文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。"),_c('br'),_vm._v(" 問31～問60は、文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選択してください。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("60点満点で、"),_c('strong',[_vm._v("36点以上")]),_vm._v("が合格です。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("試験終了後、試験結果が表示されます。"),_c('strong',[_vm._v("※この機能は実際のＣＢＴ試験にはありません。")])])
}]

export { render, staticRenderFns }