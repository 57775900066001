
export default [
    {
        "id": 1,
        "shikenId": 1,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "税理士資格を有しないファイナンシャル・プランナーが、顧客のために相談業務の延長として確定申告書を作成した場合、その行為が無償であれば税理士法に抵触しない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "関連法規とコンプライアンス",
        "important": "A"
    },
    {
        "id": 2,
        "shikenId": 1,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "遺族基礎年金を受給することができる遺族は、国民年金の被保険者等の死亡の当時に、その者によって生計を維持されており、かつ、所定の要件を満たす「子のある妻」または「子」である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "遺族基礎年金",
        "important": "C"
    },
    {
        "id": 3,
        "shikenId": 1,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "労働者災害補償保険（労災保険）の保険料は、労働者と事業主が折半で負担する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "労働者災害補償保険",
        "important": "C"
    },
    {
        "id": 4,
        "shikenId": 1,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "確定拠出年金の老齢給付金を60歳から受給するには、60歳到達時の通算加入者等期間が10年以上なければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "確定拠出年金",
        "important": "A"
    },
    {
        "id": 5,
        "shikenId": 1,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "住宅金融支援機構と民間金融機関が提携した住宅ローンであるフラット35の融資金利は固定金利であるが、その利率は取扱金融機関がそれぞれ独自に決定している。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "フラット35",
        "important": "B"
    },
    {
        "id": 6,
        "shikenId": 1,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "国内銀行の窓口で加入した生命保険契約については、生命保険契約者保護機構による補償の対象とならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "保険契約者保護",
        "important": "B"
    },
    {
        "id": 7,
        "shikenId": 1,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "生命保険の保険料のうち、保険会社が保険契約を維持・管理するための費用に当てられる付加保険料は、予定死亡率および予定利率に基づいて計算される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険料の仕組み",
        "important": "A"
    },
    {
        "id": 8,
        "shikenId": 1,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "一般に、収入保障保険の死亡保険金を年金形式で受け取る場合の受取総額は、一時金で受け取る場合の受取額よりも少なくなる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "収入保障保険",
        "important": "C"
    },
    {
        "id": 9,
        "shikenId": 1,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "生命保険契約にある入院特約に基づき、被保険者が病気で入院したことで被保険者が受け取った入院給付金は、非課税である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "給付金と税金",
        "important": "B"
    },
    {
        "id": 10,
        "shikenId": 1,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "自動車損害賠償責任保険において、被害者１人当たりの保険金の支払限度額は、死亡の場合で3,000万円、後遺障害の場合で5,000万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "自動車損害賠償責任保険",
        "important": "B"
    },
    {
        "id": 11,
        "shikenId": 1,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "米国の市場金利が上昇し、日本の市場金利が低下することは、米ドルと円の為替相場においては、一般に、米ドル安、円高の要因となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "為替相場の変動要因",
        "important": "B"
    },
    {
        "id": 12,
        "shikenId": 1,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "上場投資信託（ＥＴＦ）は、上場株式と同様に、指値注文や成行注文により売買することができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "上場投資信託（ETF）",
        "important": "C"
    },
    {
        "id": 13,
        "shikenId": 1,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "債券を発行する企業の信用度が低下し、格付が引き下げられた場合、一般に、その債券の価格は下落し、利回りも低下する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "債券のリスク",
        "important": "A"
    },
    {
        "id": 14,
        "shikenId": 1,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "Ｘ社の株価が1,200円、１株当たり純利益が24円、１株当たり年間配当金が12円である場合、Ｘ社株式の配当利回りは、１％である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "株式の投資指標の計算",
        "important": "A"
    },
    {
        "id": 15,
        "shikenId": 1,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "預金保険制度の対象金融機関に預け入れた決済用預金は、預入金額にかかわらず、その全額が預金保険制度による保護の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "預金保険制度",
        "important": "B"
    },
    {
        "id": 16,
        "shikenId": 1,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "個人が法人からの贈与により取得した財産は、原則として贈与税の課税対象となり、所得税は課されない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "贈与財産の課税",
        "important": "B"
    },
    {
        "id": 17,
        "shikenId": 1,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "電車・バス等の交通機関を利用して通勤している給与所得者が、勤務先から受ける通勤手当の所得税法上の非課税限度額は、月額10万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "非課税所得",
        "important": "C"
    },
    {
        "id": 18,
        "shikenId": 1,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "所得税において、事業的規模で行われている賃貸マンションの貸付で得た所得は、不動産所得となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "不動産所得",
        "important": "B"
    },
    {
        "id": 19,
        "shikenId": 1,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "所得税における基礎控除の額は、納税者の合計所得金額の多寡にかかわらず、一律で38万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "基礎控除",
        "important": "C"
    },
    {
        "id": 20,
        "shikenId": 1,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "夫が生計を一にする妻の負担すべき国民年金の保険料を支払った場合、その支払った金額は、夫の所得税において、社会保険料控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "社会保険料控除",
        "important": "B"
    },
    {
        "id": 21,
        "shikenId": 1,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "不動産の登記事項証明書の交付請求は、対象不動産の所有者のみが行うことができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産登記",
        "important": "A"
    },
    {
        "id": 22,
        "shikenId": 1,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "不動産取引において、買主が売主に解約手付を交付したときは、相手方が契約の履行に着手するまでは、買主はその手付を放棄することで、売主はその手付を買主に現実に提供することで、契約を解除することができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "手付金",
        "important": "B"
    },
    {
        "id": 23,
        "shikenId": 1,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "都市計画法の規定によれば、市街化区域内で行う開発行為は、その規模にかかわらず、都道府県知事等の許可が必要である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "開発許可",
        "important": "B"
    },
    {
        "id": 24,
        "shikenId": 1,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "不動産取得税は、生前贈与や相続により不動産を取得したときには課されない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産取得税",
        "important": "C"
    },
    {
        "id": 25,
        "shikenId": 1,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "土地の譲渡所得のうち、その土地を譲渡した日の属する年の１月１日における所有期間が10年以下のものは、短期譲渡所得に区分される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産の譲渡と所有期間",
        "important": "B"
    },
    {
        "id": 26,
        "shikenId": 1,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "相続人が相続の放棄をする場合は、自己のために相続の開始があったことを知った時から、原則として10カ月以内に、家庭裁判所にその旨を申述しなければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続の放棄",
        "important": "C"
    },
    {
        "id": 27,
        "shikenId": 1,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "相続税額の計算上、被相続人が生前に購入した墓碑の購入代金で、相続開始時において未払いのものは、債務控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "債務控除",
        "important": "B"
    },
    {
        "id": 28,
        "shikenId": 1,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "本年中に相続や遺贈により財産を取得した者が、相続開始前５年以内に被相続人から暦年課税方式の贈与により取得した財産は、相続税額の計算上、相続財産に加算される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "生前贈与加算",
        "important": "C"
    },
    {
        "id": 29,
        "shikenId": 1,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "「直系尊属から教育資金の一括贈与を受けた場合の贈与税の非課税」は、贈与を受ける年の受贈者の合計所得金額が1,000万円を超える場合、適用を受けることができない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税非課税（教育資金）",
        "important": "B"
    },
    {
        "id": 30,
        "shikenId": 1,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "相続財産の評価において、相続開始時に保険事故が発生していない生命保険契約に関する権利の価額は、原則として、既払込保険料相当額により評価する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "生命保険契約に関する権利",
        "important": "C"
    },
    {
        "id": 31,
        "shikenId": 1,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "借入金額2,000万円、利率（年率・複利）1％、返済期間15年、元利均等返済でローンを組む場合、毎年の返済額は、下記の＜資料＞の係数を使用して算出すると、（　　）である。<br><br>＜資料＞利率（年率）１％・期間15年の各種係数<br><table><tr><td>現価係数</td><td>資本回収係数</td><td>減債基金係数</td></tr><tr><td>0.8613</td><td>0.0721</td><td>0.0621</td></tr></table>",
        "choice1": "1,242,000円",
        "choice2": "1,442,000円",
        "choice3": "1,935,059円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "係数計算",
        "important": "A"
    },
    {
        "id": 32,
        "shikenId": 1,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "全国健康保険協会管掌健康保険の被保険者に支給される傷病手当金の額は、１日につき、原則として、傷病手当金の支給を始める日の属する月以前の直近の継続した（ ① ）の各月の標準報酬月額の平均額を30で除した額に、（ ② ）を乗じた額である。",
        "choice1": "①　12カ月間　　②　２分の１",
        "choice2": "①　12カ月間　　②　３分の２",
        "choice3": "①　６カ月間　　②　４分の３",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "傷病手当金",
        "important": "B"
    },
    {
        "id": 33,
        "shikenId": 1,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "雇用保険の基本手当を受給するためには、倒産、解雇および雇止めなどの場合を除き、原則として、離職の日以前（ ① ）に被保険者期間が通算して（ ② ）以上あることなどの要件を満たす必要がある。",
        "choice1": "①　１年間　　②　６カ月",
        "choice2": "①　１年間　　②　12カ月",
        "choice3": "①　２年間　　②　12カ月",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "雇用保険の基本手当",
        "important": "B"
    },
    {
        "id": 34,
        "shikenId": 1,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "厚生年金保険の被保険者期間が原則として（ ① ）以上ある者が、老齢厚生年金の受給権を取得した当時、当該受給権者と生計を同じくしている（ ② ）未満の配偶者が所定の要件を満たしている場合、当該受給権者が受給する老齢厚生年金に加給年金額が加算される。",
        "choice1": "①　10年　　②　60歳",
        "choice2": "①　20年　　②　65歳",
        "choice3": "①　25年　　②　65歳",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "加給年金",
        "important": "B"
    },
    {
        "id": 35,
        "shikenId": 1,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "住宅ローンの返済方法で、元利均等返済方式と元金均等返済方式とで利息を含めた総返済金額を比較すると、返済期間や金利などの他の条件が同一である場合、通常、その額は、（　　）。",
        "choice1": "元利均等返済方式のほうが多い",
        "choice2": "元金均等返済方式のほうが多い",
        "choice3": "どちらも同じ額である",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "元利均等と元金均等",
        "important": "B"
    },
    {
        "id": 36,
        "shikenId": 1,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "国内で事業を行う生命保険会社が破綻した場合、生命保険契約者保護機構による補償の対象となる保険契約は、高予定利率契約を除き、（ ① ）の（ ② ）まで補償される。",
        "choice1": "①　責任準備金等　　②　90％",
        "choice2": "①　死亡保険金額　　②　100％",
        "choice3": "①　責任準備金等　　②　80％",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "保険契約者保護",
        "important": "B"
    },
    {
        "id": 37,
        "shikenId": 1,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "定期保険特約付終身保険では、定期保険特約の保険金額を同額で自動更新すると、通常は、更新後の保険料が、更新前（　　）。",
        "choice1": "よりも安くなる",
        "choice2": "と変わらない",
        "choice3": "よりも高くなる",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "定期保険の更新",
        "important": "C"
    },
    {
        "id": 38,
        "shikenId": 1,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "生命保険契約の契約者は、契約者貸付制度を利用することで、契約している生命保険の（　　）の一定の範囲内で保険会社から貸付を受けることができる。",
        "choice1": "解約返戻金額",
        "choice2": "既払込保険料総額",
        "choice3": "死亡保険金額",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "契約者貸付",
        "important": "C"
    },
    {
        "id": 39,
        "shikenId": 1,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税において、個人が本年中に締結した生命保険契約に基づく支払保険料のうち、（　　）に係る保険料は、介護医療保険料控除の対象となる。",
        "choice1": "傷害特約",
        "choice2": "定期保険特約",
        "choice3": "先進医療特約",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険料控除",
        "important": "B"
    },
    {
        "id": 40,
        "shikenId": 1,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "家族傷害保険に付帯された個人賠償責任補償特約においては、（　　）により損害賠償責任を負った場合は補償の対象とならない。",
        "choice1": "同居する家族に誤ってけがを負わせた事故",
        "choice2": "別居の未婚の子が自転車で走行中に起こした事故",
        "choice3": "飼い犬が他人を噛んでけがを負わせた事故",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "家族傷害保険",
        "important": "C"
    },
    {
        "id": 41,
        "shikenId": 1,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "一定期間内に国内で生産された財やサービスの付加価値の合計額を（ ① ）といい、その統計は（ ② ）が作成し、公表する。",
        "choice1": "①　国民総生産（ＧＮＰ）　　②　内閣府",
        "choice2": "①　国内総生産（ＧＤＰ）　　②　日本銀行",
        "choice3": "①　国内総生産（ＧＤＰ）　　②　内閣府",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "国内総生産（GDP）",
        "important": "C"
    },
    {
        "id": 42,
        "shikenId": 1,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "追加型株式投資信託を基準価額１万3,000円で１万口購入した後、最初の決算時に１万口当たり400円の収益分配金が支払われ、分配落ち後の基準価額が１万2,800円となった場合、その収益分配金のうち、普通分配金は（ ① ）であり、元本払戻金（特別分配金）は（ ② ）である。",
        "choice1": "①　400円　　②　0円",
        "choice2": "①　100円　　②　300円",
        "choice3": "①　200円　　②　200円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "投資信託の分配金の計算",
        "important": "B"
    },
    {
        "id": 43,
        "shikenId": 1,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "表面利率（クーポンレート）２％、残存期間４年の固定利付債券を、額面100円当たり104円で購入し、２年後に額面100円当たり103円で売却した場合の所有期間利回り（年率・単利）は、（　　）％である。なお、税金や手数料等は考慮しないものとし、答は表示単位の小数点以下第３位を四捨五入している。",
        "choice1": "0.96",
        "choice2": "1.44",
        "choice3": "2.40",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "債券の利回りの計算",
        "important": "A"
    },
    {
        "id": 44,
        "shikenId": 1,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "Ａ資産の期待収益率が2.0％、Ｂ資産の期待収益率が4.0％の場合に、Ａ資産を60％、Ｂ資産を40％の割合で組み入れたポートフォリオの期待収益率は、（　　）となる。",
        "choice1": "1.6％",
        "choice2": "2.4％",
        "choice3": "2.8％",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "期待収益率の計算",
        "important": "C"
    },
    {
        "id": 45,
        "shikenId": 1,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "為替予約を締結していない外貨定期預金の場合、満期時の為替レートが預入時の為替レートに比べて（ ① ）になれば、円換算の利回りは（ ② ）なる。",
        "choice1": "①　円安　　②　高く",
        "choice2": "①　円安　　②　低く",
        "choice3": "①　円高　　②　高く",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "外貨預金",
        "important": "C"
    },
    {
        "id": 46,
        "shikenId": 1,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "国内において支払を受ける預貯金の利子は、原則として、国税（復興特別所得税を含む）と地方税を合わせて（ ① ）の税率による（ ② ）分離課税の対象となる。",
        "choice1": "①　15.315％　　②　申告",
        "choice2": "①　20.315％　　②　申告",
        "choice3": "①　20.315％　　②　源泉",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "利子所得",
        "important": "C"
    },
    {
        "id": 47,
        "shikenId": 1,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "給与所得者が、30年間勤務した会社を定年退職し、退職金3,000万円の支払を受けた。この場合、所得税の退職所得の金額を計算する際の退職所得控除額は、（　　）となる。",
        "choice1": "800万円＋70万円×（30年－20年）×1/2=1,150万円",
        "choice2": "800万円＋40万円×（30年－20年）＝1,200万円",
        "choice3": "800万円＋70万円×（30年－20年）＝1,500万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "退職所得の計算",
        "important": "A"
    },
    {
        "id": 48,
        "shikenId": 1,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "Ａさんの本年分の各種所得の金額が下記の＜資料＞のとおりであった場合、損益通算後の総所得金額は（　　）となる。なお、各種所得の金額に付されている「▲」は、その所得に損失が生じていることを表すものとする。<br>＜資料＞Aさんの本年分の各種所得の金額<br><table><tr><td>不動産所得の金額</td><td>850万円</td></tr><tr><td>雑所得の金額</td><td>▲50万円</td></tr><tr><td>事業所得の金額（株式等に係るものを除く）</td><td>▲200万円</td></tr></table>",
        "choice1": "600万円",
        "choice2": "650万円",
        "choice3": "800万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": "A"
    },
    {
        "id": 49,
        "shikenId": 1,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税の確定申告をしなければならない者は、原則として、所得が生じた年の翌年の（ ① ）から（ ② ）までの間に、納税地の所轄税務署長に対して確定申告書の提出が必要となる。",
        "choice1": "①　２月１日　　②　３月15日",
        "choice2": "①　２月16日　　②　３月15日",
        "choice3": "①　２月１日　　②　３月31日",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税の確定申告の期限",
        "important": "C"
    },
    {
        "id": 50,
        "shikenId": 1,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "年末調整の対象となる給与所得者は、年末調整により、（　　）の適用を受けることができる。",
        "choice1": "雑損控除",
        "choice2": "医療費控除",
        "choice3": "生命保険料控除",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "年末調整",
        "important": "B"
    },
    {
        "id": 51,
        "shikenId": 1,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "都道府県地価調査の基準地の標準価格は、毎年（ ① ）を価格判定の基準日として調査され、都道府県知事により毎年（ ② ）頃に公表される。",
        "choice1": "①　１月１日　　②　３月",
        "choice2": "①　１月１日　　②　７月",
        "choice3": "①　７月１日　　②　９月",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "土地の価格",
        "important": "B"
    },
    {
        "id": 52,
        "shikenId": 1,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "建築基準法上、都市計画区域および準都市計画区域内において、建築物の敷地は、原則として、幅員（ ① ）以上の道路に（ ② ）以上接していなければならない。",
        "choice1": "①　４ｍ　　②　１ｍ",
        "choice2": "①　４ｍ　　②　２ｍ",
        "choice3": "①　６ｍ　　②　３ｍ",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "接道義務",
        "important": "A"
    },
    {
        "id": 53,
        "shikenId": 1,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "建築物が防火地域および準防火地域にわたる場合においては、原則として、その全部について（　　）内の建築物に関する規定が適用される。",
        "choice1": "防火地域",
        "choice2": "準防火地域",
        "choice3": "敷地の過半が属する地域",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "防火規制",
        "important": "C"
    },
    {
        "id": 54,
        "shikenId": 1,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "建物の区分所有等に関する法律（区分所有法）において、集会において、区分所有者および議決権の各（　　）以上の多数により、区分所有建物を取り壊し、その敷地上に新たに建物を建築する旨の決議（建替え決議）をすることができる。",
        "choice1": "２分の１",
        "choice2": "４分の３",
        "choice3": "５分の４",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "区分所有法",
        "important": "A"
    },
    {
        "id": 55,
        "shikenId": 1,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "「特定居住用財産の譲渡損失の損益通算及び繰越控除の特例」の適用を受けた場合、損益通算を行っても控除しきれなかった譲渡損失の金額について繰越控除が認められるのは、譲渡の年の翌年以後、最長で（　　）以内である。",
        "choice1": "３年",
        "choice2": "５年",
        "choice3": "10年",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "居住用財産の譲渡損失の特例",
        "important": "C"
    },
    {
        "id": 56,
        "shikenId": 1,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "贈与契約における財産の取得時期については、原則として、書面による贈与の場合は（ ① ）、書面によらない贈与の場合は（ ② ）とされる。",
        "choice1": "①　贈与契約の効力が発生した時　　②　贈与の履行があった時",
        "choice2": "①　贈与契約の効力が発生した時　　②　贈与の意思表示をした時",
        "choice3": "①　贈与の履行があった時　　②　贈与の意思表示をした時",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与契約の取得時期",
        "important": "C"
    },
    {
        "id": 57,
        "shikenId": 1,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "下記の〈親族関係図〉において、Ａさんが亡くなった場合の相続における、妻Ｂさんの法定相続分は、（　　）である。<br>＜親族関係図＞<br><img src=\"/img/1_1.png\" style='width: 350px;'/>",
        "choice1": "２分の１",
        "choice2": "３分の２",
        "choice3": "４分の３",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "法定相続分",
        "important": "A"
    },
    {
        "id": 58,
        "shikenId": 1,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "相続税の計算においては、相続人が受け取った死亡保険金の非課税限度額を、「（ ① ）×法定相続人の数」の算式により算出するが、相続人のうち相続の放棄をした者がいる場合、当該法定相続人の数は、相続を放棄した者を（ ② ）人数とされる。",
        "choice1": "①　500万円　　②　含む",
        "choice2": "①　500万円　　②　含まない",
        "choice3": "①　600万円　　②　含まない",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "死亡保険金の非課税限度額",
        "important": "B"
    },
    {
        "id": 59,
        "shikenId": 1,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "2023年中に相続時精算課税の適用を受けた場合、特定贈与者ごとに特別控除額として累計（ ① ）までの贈与には贈与税が課されず、その額を超えた部分については一律（ ② ）％の税率により贈与税が課される。",
        "choice1": "①　1,500万円　　②　15",
        "choice2": "①　1,500万円　　②　20",
        "choice3": "①　2,500万円　　②　20",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続時精算課税",
        "important": "B"
    },
    {
        "id": 60,
        "shikenId": 1,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "自用地としての価額が6,000万円、借地権割合が60％、借家権割合が30％、賃貸割合が100％の貸家建付地の相続税評価額は、（　　）である。",
        "choice1": "2,400万円",
        "choice2": "3,600万円",
        "choice3": "4,920万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "貸家建付地の計算",
        "important": "B"
    },
    {
        "id": 61,
        "shikenId": 2,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "ファイナンシャル・プランナーが顧客と投資顧問契約を締結し、当該契約に基づき金融商品取引法で定める投資助言・代理業を行うためには、内閣総理大臣の登録を受けなければならない。(2022.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 62,
        "shikenId": 2,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "国民健康保険の被保険者は、原則として、70歳に達した時にその資格を喪失し、後期高齢者医療制度の被保険者となる。(2019.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 63,
        "shikenId": 2,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "国民年金の第1号被保険者の収入により生計を維持する配偶者で、20歳以上60歳未満の者は、国民年金の第3号被保険者となることができる。(2021.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 64,
        "shikenId": 2,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "老齢厚生年金に加給年金額が加算されるためには、原則として、老齢厚生年金の受給権者本人の厚生年金保険の被保険者期間が20年以上なければならない。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 65,
        "shikenId": 2,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "住宅金融支援機構と民間金融機関が提携した住宅ローンであるフラット35の融資金利は固定金利であり、その利率は取扱金融機関がそれぞれ独自に決定している。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 66,
        "shikenId": 2,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "生命保険契約を申し込んだ者は、保険業法上、原則として、契約の申込日から8日以内であれば、口頭により申込みの撤回等をすることができる。(2020.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 67,
        "shikenId": 2,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "収入保障保険の死亡保険金を年金形式で受け取る場合の受取総額は、一般に、一時金で受け取る場合の受取額よりも少なくなる。(2022.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 68,
        "shikenId": 2,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "普通傷害保険では、特約を付帯していない場合、細菌性食中毒は補償の対象とならない。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 69,
        "shikenId": 2,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "居住用建物および家財を対象とした火災保険では、地震もしくは噴火またはこれらによる津波を原因とする損害は、補償の対象とならない。(2021.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 70,
        "shikenId": 2,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "リビング・ニーズ特約は、被保険者の余命が6カ月以内と判断された場合に、所定の範囲内で死亡保険金の一部または全部を生前に受け取ることができる特約である。(2021.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 71,
        "shikenId": 2,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "全国企業短期経済観測調査（日銀短観）は、企業間で取引されている財に関する物価の変動を測定した指標である。(2021.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 72,
        "shikenId": 2,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "元金1,000,000円を年利1％の1年複利で2年間運用した場合の元利合計金額は、税金や手数料等を考慮しない場合、1,020,100円である。(2019.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 73,
        "shikenId": 2,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "投資信託の運用管理費用（信託報酬）は、投資信託を購入する際に年間分を前払いで支払う必要がある。(2020.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 74,
        "shikenId": 2,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "日経平均株価は、東京証券取引所プライム市場に上場する代表的な225銘柄を対象として算出される株価指標である。(2020.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 75,
        "shikenId": 2,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "オプション取引において、他の条件が同じであれば、満期までの残存期間が短いほど、プレミアム（オプション料）は高くなる。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 76,
        "shikenId": 2,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "所得税において、老齢基礎年金や老齢厚生年金を受け取ったことによる所得は、非課税所得となる。(2023.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 77,
        "shikenId": 2,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "所得税における一時所得に係る総収入金額が500万円で、その収入を得るために支出した金額が400万円である場合、総所得金額に算入される一時所得の金額は、50万円である。(2021.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 78,
        "shikenId": 2,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "所得税において、NISA口座（少額投資非課税制度における非課税口座）内で生じた上場株式の譲渡損失の金額は、特定口座内の上場株式の譲渡益の金額と損益を通算することができる。(2021.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 79,
        "shikenId": 2,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "所得税において、生計を一にする配偶者の合計所得金額が48万円を超える場合、配偶者控除の適用を受けることはできない。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 80,
        "shikenId": 2,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "給与所得者は、年末調整により、所得税の医療費控除の適用を受けることができる。(2022.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 81,
        "shikenId": 2,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "土地および家屋に係る固定資産税評価額は、原則として、3年ごとの基準年度において評価替えが行われる。(2021.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 82,
        "shikenId": 2,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "借地借家法において、定期建物賃貸借契約（定期借家契約）では、契約当事者の合意があっても、存続期間を1年未満とすることはできない。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 83,
        "shikenId": 2,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "都市計画法の規定によれば、市街化区域内で行う開発行為は、その規模にかかわらず、都道府県知事等の許可を受けなければならない。(2020.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 84,
        "shikenId": 2,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "固定資産税における小規模住宅用地（住宅用地で住宅1戸当たり200㎡以下の部分）の課税標準については、当該住宅用地に係る固定資産税の課税標準となるべき価格の5分の1の額とする特例がある。(2019.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 85,
        "shikenId": 2,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "土地の有効活用において、一般に、土地所有者が入居予定の事業会社から建設資金を借り受けて、事業会社の要望に沿った店舗等を建設し、その店舗等を事業会社に賃貸する手法を、建設協力金方式という。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 86,
        "shikenId": 2,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "書面によらない贈与契約は、既に履行が終わった部分を除き、各当事者が解除をすることができる。(2021.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 87,
        "shikenId": 2,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "相続人は、原則として、自己のために相続の開始があったことを知った時から3カ月以内に、相続について単純承認または限定承認をしなければ、相続の放棄をしたものとみなされる。(2023.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 88,
        "shikenId": 2,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "被相続人の直系尊属で、法定相続人である者は、遺留分権利者となる。(2022.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 89,
        "shikenId": 2,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "法定相続人である被相続人の兄が相続により財産を取得した場合、その者は相続税額の2割加算の対象となる。(2021.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 90,
        "shikenId": 2,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "相続財産の評価において、相続開始時に保険事故が発生していない生命保険契約に関する権利の価額は、原則として、既払込保険料相当額によって評価する。(2019.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 91,
        "shikenId": 2,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "Aさんの2023年分の可処分所得の金額は、下記の＜資料＞によれば、（  ）である。(2021.09)<br><img src=\"/img/2_1.png\" style='width: 450px;'/>",
        "choice1": "360万円",
        "choice2": "530万円",
        "choice3": "540万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 92,
        "shikenId": 2,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "全国健康保険協会管掌健康保険の被保険者が、産科医療補償制度に加入する医療機関で出産した場合の出産育児一時金の額は、1児につき（　）である。(2022.01)",
        "choice1": "42万円",
        "choice2": "50万円",
        "choice3": "56万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 93,
        "shikenId": 2,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "国民年金の第1号被保険者が、国民年金の定額保険料に加えて月額（①）の付加保険料を納付し、65歳から老齢基礎年金を受け取る場合、（②）に付加保険料納付済期間の月数を乗じて得た額が付加年金として支給される。(2023.05)",
        "choice1": "① 400円　　② 200円",
        "choice2": "① 400円　　② 300円",
        "choice3": "① 200円　　② 400円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 94,
        "shikenId": 2,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "日本学生支援機構が取り扱う奨学金には、（①）第一種奨学金と（②）第二種奨学金がある。(2020.01)",
        "choice1": "① 利息付（在学中は無利息）の　　② 利息付（在学中も有利息）の",
        "choice2": "① 無利息の　　② 利息付（在学中は無利息）の",
        "choice3": "① 返済義務のない　　② 無利息の",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 95,
        "shikenId": 2,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "貸金業法の総量規制により、個人が貸金業者による個人向け貸付を利用する場合の借入合計額は、原則として、年収の（　）以内でなければならない。(2022.09)",
        "choice1": "2分の1",
        "choice2": "3分の1",
        "choice3": "4分の1",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 96,
        "shikenId": 2,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "ソルベンシー・マージン比率は、保険会社が、通常の予測を超えて発生するリスクに対し、保険金等の支払余力をどの程度有するかを示す指標であり、この値が（  ）を下回ると、監督当局による早期是正措置の対象となる。(2021.05)",
        "choice1": "200％",
        "choice2": "250％",
        "choice3": "300％",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 97,
        "shikenId": 2,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "生命保険の保険料は、将来の保険金・給付金等の支払の財源となる（①）と、保険会社が保険契約を維持・管理していくために必要な経費等の財源となる（②）で構成されている。(2021.01)",
        "choice1": "① 終身保険料　　② 定期保険料",
        "choice2": "① 純保険料　　② 付加保険料",
        "choice3": "① 定額保険料　　② 変額保険料",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 98,
        "shikenId": 2,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "個人賠償責任保険（特約）では、被保険者が、（  ）、法律上の損害賠償責任を負うことによって被る損害は、補償の対象とならない。(2022.05)",
        "choice1": "自宅のベランダから誤って植木鉢を落として駐車中の自動車を傷付けてしまい",
        "choice2": "買い物中に誤って商品を落として破損させてしまい",
        "choice3": "業務中に自転車で歩行者に衝突してケガをさせてしまい",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 99,
        "shikenId": 2,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "小売業を営む企業が、火災・爆発等によって営業が休止または阻害されたことによる利益の減少等に備える保険は、（  ）である。(2018.05)",
        "choice1": "労働災害総合保険",
        "choice2": "施設所有（管理）者賠償責任保険",
        "choice3": "企業費用・利益総合保険",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 100,
        "shikenId": 2,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "がん保険では、一般に、（  ）程度の免責期間が設けられており、この期間中にがんと診断されたとしても診断給付金は支払われない。(2023.05)",
        "choice1": "90日",
        "choice2": "120日",
        "choice3": "180日",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 101,
        "shikenId": 2,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "追加型株式投資信託を基準価額1万800円で1万口購入した後、最初の決算時に1万口当たり300円の収益分配金が支払われ、分配落ち後の基準価額が1万600円となった場合、その収益分配金のうち、普通分配金は（①）であり、元本払戻金（特別分配金）は（②）となる。(2020.01)",
        "choice1": "① 100円　　② 200円",
        "choice2": "① 200円　　② 100円",
        "choice3": "① 300円　　② 0円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 102,
        "shikenId": 2,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "表面利率（クーポンレート）1％、残存期間2年の固定利付債券を額面100円当たり99円で購入した場合の最終利回り（年率・単利）は、（  ）である。なお、税金等は考慮しないものとし、計算結果は表示単位の小数点以下第3位を四捨五入している。(2022.05)",
        "choice1": "1.50％",
        "choice2": "1.52％",
        "choice3": "2.02％",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 103,
        "shikenId": 2,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "外貨預金の払戻し時において、預金者が外貨を円貨に換える場合に適用される為替レートは、預入金融機関が提示する（  ）である。(2019.05)",
        "choice1": "TTS",
        "choice2": "TTM",
        "choice3": "TTB",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 104,
        "shikenId": 2,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "日本投資者保護基金は、会員である金融商品取引業者が破綻し、分別管理の義務に違反したことによって、一般顧客から預託を受けていた有価証券・金銭を返還することができない場合、一定の範囲の取引を対象に一般顧客1人につき（  ）を上限に金銭による補償を行う。(2022.05)",
        "choice1": "1,000万円",
        "choice2": "1,300万円",
        "choice3": "2,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 105,
        "shikenId": 2,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "異なる2資産からなるポートフォリオにおいて、2資産間の相関係数が（  ）である場合、分散投資によるリスクの低減効果は最大となる。(2021.09)",
        "choice1": "－1",
        "choice2": "0",
        "choice3": "＋1",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 106,
        "shikenId": 2,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "課税総所得金額250万円に対する所得税額（復興特別所得税額を含まない）は、下記の＜資料＞を使用して算出すると、（  ）である。(2020.09)<br><img src=\"/img/2_2.png\" style='width: 350px;'/>",
        "choice1": "97,500円",
        "choice2": "152,500円",
        "choice3": "250,000円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 107,
        "shikenId": 2,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税において、2023年中に取得した建物（鉱業用減価償却資産等を除く）に係る減価償却の方法は、（  ）である。(2021.01)",
        "choice1": "定額法",
        "choice2": "定率法",
        "choice3": "定額法および定率法",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 108,
        "shikenId": 2,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税において、為替予約を締結していない外貨定期預金を満期時に円貨で払い戻した結果生じた為替差益は、（  ）として総合課税の対象となる。(2022.09)",
        "choice1": "利子所得",
        "choice2": "一時所得",
        "choice3": "雑所得",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 109,
        "shikenId": 2,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税において、 納税者の合計所得金額が2,400万円以下である場合、基礎控除の額は、（  ）である。(2022.09)",
        "choice1": "38万円",
        "choice2": "48万円",
        "choice3": "63万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 110,
        "shikenId": 2,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税において、上場株式の配当について配当控除の適用を受けるためには、その配当所得について（  ）を選択する必要がある。(2023.01)",
        "choice1": "総合課税",
        "choice2": "申告分離課税",
        "choice3": "確定申告不要制度",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 111,
        "shikenId": 2,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "相続税路線価は、地価公示の公示価格の（①）を価格水準の目安として設定されており、（②）のホームページで閲覧可能な路線価図で確認することができる。(2023.05)",
        "choice1": "① 70％　　② 国土交通省",
        "choice2": "① 80％　　② 国税庁",
        "choice3": "① 90％　　② 国税庁",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 112,
        "shikenId": 2,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "建築基準法において、都市計画区域および準都市計画区域内にある建築物の敷地は、原則として、幅員4ｍ以上の道路に（  ）以上接していなければならない。(2022.05)",
        "choice1": "2ｍ",
        "choice2": "4ｍ",
        "choice3": "6ｍ",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 113,
        "shikenId": 2,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "幅員6ｍの市道に12ｍ接する200㎡の敷地に、建築面積が120㎡、延べ面積が180㎡の2階建ての住宅を建築する場合、この住宅の容積率は、（  ）となる。(2020.09)",
        "choice1": "60％",
        "choice2": "66％",
        "choice3": "90％",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 114,
        "shikenId": 2,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税の計算において、個人が土地を譲渡したことによる譲渡所得が長期譲渡所得に区分されるためには、土地を譲渡した年の1月1日における所有期間が（  ）を超えていなければならない。(2023.01)",
        "choice1": "5年",
        "choice2": "10年",
        "choice3": "20年",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 115,
        "shikenId": 2,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "被相続人の居住用家屋およびその敷地を相続により取得した被相続人の長男が、当該家屋およびその敷地を譲渡し、「被相続人の居住用財産（空き家）に係る譲渡所得の特別控除の特例」の適用を受けた場合、譲渡所得の金額の計算上、最高（  ）を控除することができる。(2021.09)",
        "choice1": "2,000万円",
        "choice2": "3,000万円",
        "choice3": "5,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 116,
        "shikenId": 2,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "「直系尊属から教育資金の一括贈与を受けた場合の贈与税の非課税」の適用を受けた場合、受贈者1人につき（①）までは贈与税が非課税となるが、学校等以外の者に対して直接支払われる金銭については、（②）が限度となる。(2021.09)",
        "choice1": "① 1,000万円　　② 500万円",
        "choice2": "① 1,500万円　　② 500万円",
        "choice3": "① 1,500万円　　② 1,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 117,
        "shikenId": 2,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "公正証書遺言は、証人2人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がそれを筆記して作成される遺言であり、相続開始後に（①）における検認手続が（②）である。(2022.05)",
        "choice1": "① 公証役場　　② 必要",
        "choice2": "① 家庭裁判所　　② 必要",
        "choice3": "① 家庭裁判所　　② 不要",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 118,
        "shikenId": 2,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "下記の＜親族関係図＞において、被相続人Aさんの相続における相続税額の計算上、遺産に係る基礎控除額は（  ）である。(2023.01)<br><img src=\"/img/2_3.png\" style='width: 500px;'/>",
        "choice1": "4,200万円",
        "choice2": "4,800万円",
        "choice3": "5,400万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 119,
        "shikenId": 2,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "2022年９月７日（水）に死亡したＡさんが所有していた上場株式Ｘを相続により取得した場合の１株当たりの相続税評価額は、下記の＜資料＞によれば、（  ）である。(2022.09)<br><img src=\"/img/2_4.png\" style='width: 420px;'/>",
        "choice1": "1,180円",
        "choice2": "1,190円",
        "choice3": "1,200円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 120,
        "shikenId": 2,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "相続人が相続により取得した宅地が「小規模宅地等についての相続税の課税価格の計算の特例」における特定事業用宅地等に該当する場合、その宅地のうち（①）までを限度面積として、評価額の（②）相当額を減額した金額を、相続税の課税価格に算入すべき価額とすることができる。(2023.05)",
        "choice1": "① 200㎡　　② 50％",
        "choice2": "① 330㎡　　② 80％",
        "choice3": "① 400㎡　　② 80％",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    }

]